@use 'sass:math';

@import 'Styles/includes';

.SlickSlider {
    $root: &;

    position: relative;
    padding-bottom: 40px + 16px;

    @include media(M) {
        padding-bottom: 44px + 16px;
    }

    &__Button {
        position: absolute !important;
        bottom: 0;
        right: 0;
        left: 0;
        left: 40px + 12px;
        width: 40px;
        height: 40px;
        margin: auto;

        @include media(M) {
            left: 44px + 12px;
            width: 44px;
            height: 44px;
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $purple-20;
            opacity: 0;
            transition: opacity $transition;

            :global(.Theme--Arkitekturgalan) & {
                background: $arkitekturgalan-primary;
            }
        }

        svg {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            fill: $grey;
            transition: fill $transition;

            :global(.Theme--Arkitekturgalan) & {
                fill: $arkitekturgalan-slider-dot-active;
            }
        }

        &:global(.slick-disabled) {
            svg {
                fill: $grey-30;

                :global(.Theme--Arkitekturgalan) & {
                    fill: $arkitekturgalan-slider-dot-disabled;
                }
            }
        }

        &:not(:global(.slick-disabled)) {
            cursor: pointer;
        }

        &:not(:global(.slick-disabled)):hover {
            &::before {
                opacity: 1;
            }

            svg {
                fill: $purple;

                :global(.Theme--Arkitekturgalan) & {
                    fill: $arkitekturgalan-slider-dot-hover;
                }
            }
        }

        #{$root}--ProgressBar & {
            right: auto;
            left: 40px + 16px;

            @include media(M) {
                bottom: 0;
                right: auto;
                left: 44px - 10px + 24px;
            }
        }

        &--Prev {
            left: 0;
            right: 40px + 12px;
            transform: rotate(180deg);

            @include media(M) {
                left: 0;
                right: 44px + 12px;
            }

            #{$root}--ProgressBar & {
                right: auto;
                left: 0;

                @include media(M) {
                    right: auto;
                    left: -10px;
                }
            }
        }

        &--Dots {
            position: relative !important;
            top: auto;
            bottom: auto;
            left: auto;
            right: auto;
            margin: 0;
        }
    }

    :global(.slick-list) {
        position: relative;
        overflow: visible;

        @include media(M) {
            margin-right: -16px;
        }

        @include media(ML) {
            margin-right: -24px;
        }

        @include media(L) {
            margin-right: -38px;
        }
    }

    &--GallerySlider :global(.slick-list) {
        margin-right: -16px;

        @include media(SL) {
            margin-right: -24px;
        }

        @include media(ML) {
            margin-right: -40px;
        }

        @include media(L) {
            margin-right: -80px;
        }
    }

    :global(.slick-track) {
        display: flex;
        min-width: 100%;
    }

    :global(.slick-slide) {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        height: auto;
        padding-right: 16px;

        @include media(M) {
            width: #{percentage(math.div(1, 3))};
        }

        @include media(ML) {
            padding-right: 24px;
        }

        @include media(L) {
            padding-right: 38px;
        }

        > div {
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
        }
    }

    &--Default :global(.slick-slide) {
        width: auto;
    }

    &--GallerySlider :global(.slick-slide) {
        padding-right: 16px;

        @include media(SL) {
            padding-right: 24px;
        }

        @include media(ML) {
            padding-right: 40px;
        }

        @include media(L) {
            padding-right: 80px;
        }
    }

    // Progress dots
    &__Progress {
        display: flex !important;
        position: absolute;
        bottom: 20px;
        left: (40px + 40px + 16px + 16px);
        right: 0;
        background: $grey-30;

        :global(.Theme--Arkitekturgalan) & {
            background: $arkitekturgalan-slider-dot-inactive;
        }

        @include media(M) {
            left: (44px + 44px - 10px + 24px + 24px);
        }
    }

    &__ProgressBar {
        height: 2px;
        background: black;
        transition: width $transition;
    }

    // Custom dots and next/prev
    &__Nav {
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__Dots {
        display: flex;
    }

    &__Dot {
        display: block;

        button {
            position: relative;
            display: block;
            width: 22px;
            height: 22px;
            padding: 7px;
            cursor: pointer;

            &::before {
                content: '';
                display: inline-block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 8px;
                height: 8px;
                background: $grey-30;
                transition: background-color $transition;
            }
        }

        &--Active {
            button::before {
                background: $grey-40;
            }
        }

        &:not(#{$root}__Dot--Active):hover {
            button::before {
                background: $purple;
            }
        }

        :global(.Theme--Arkitekturgalan) & {
            button::before {
                background: $arkitekturgalan-slider-dot-inactive;
            }

            &--Active {
                button::before {
                    background: $arkitekturgalan-slider-dot-active;
                }
            }

            &:not(#{$root}__Dot--Active):hover {
                button::before {
                    background: $arkitekturgalan-slider-dot-hover;
                }
            }
        }
    }

    &__Item {
        flex: 1 0 auto;
        display: flex !important;
        flex-direction: column;
        // width: 100% !important;
    }
}
